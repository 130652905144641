import { createBrowserHistory } from "history";
import { Redirect, Router, Switch } from "react-router-dom";
import Api from "modules/api.js";
import Dashboard from "layouts/Dashboard.jsx";
import Pages from "layouts/Pages.jsx";
import PrivateRoute from "components/PrivateRoute.jsx";
import PublicRoute from "components/PublicRoute.jsx";
import React, { Suspense } from "react";

const hist = createBrowserHistory();

const App = () => (
  <Router history={hist}>
    <Switch>
      <PublicRoute
        path="/login"
        component={Pages}
        authenticated={Api.isUserAuthenticated()}
      />
      <PrivateRoute
        exact
        path="/logout"
        component={() => {
          Api.post("/api/auth/logout", {}).then(() => {
            Api.deauthenticateUser();
          });

          return <Redirect to="/" replace />;
        }}
        authenticated={Api.isUserAuthenticated()}
      />
      <PrivateRoute
        path="/"
        component={Dashboard}
        authenticated={Api.isUserAuthenticated()}
      />
    </Switch>
  </Router>
);

export default function WrappedApp() {
  return (
    <Suspense fallback="">
      <App />
    </Suspense>
  );
}
