import React from "react";
import Api from "modules/api.js";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import withI18n from "modules/withI18n";
import Form from "@rjsf/material-ui";
import validator from "@rjsf/validator-ajv8";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import {NotificationManager} from "react-notifications";

class AlarmProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blockRendering: true,
    };
    this.instanceName = props.instanceName;
    this.appName = props.appName;
    this.endpoint = `/api/new_config/${props.instanceName}/${props.appName}/profile/`;
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  loadData() {
    Api.get(this.endpoint).then((res) => {
      this.setState({
        blockRendering: false,
        schema: res.schema,
        data: res.data});
    }).catch((e) => {
      NotificationManager.error(e.message,
        `Could not fetch alarm profile for app ${this.appName} on instance ${this.instanceName}.`);
    });
  }

  saveData(data) {
    Api.put(this.endpoint, data).then(() => {
      NotificationManager.success("Profile saved!");
    }).catch((e) => {
      NotificationManager.error(e.message, "Error saving Profile!");
    });
  }

  render() {
    if (this.state.blockRendering) {
      return (
        <h3>Loading...</h3>
      );
    }
    return (
      <Form schema={this.state.schema}
        formData={this.state.data}
        validator={validator}
        onSubmit={(e) => this.saveData(e.formData)} />
    );
  }
}

AlarmProfile.propTypes = {
  instanceName: PropTypes.string,
  appName: PropTypes.string,
};

export default withI18n(withStyles(userProfileStyles)(AlarmProfile));
