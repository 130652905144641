import React from "react";

import Annotation from "react-image-annotation";
import Button from "components/CustomButtons/Button.jsx";
import PerspectiveChangeButton from "components/Config/PerspectiveChangeButton.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import Clear from "@material-ui/icons/Clear";
import defaultImage from "assets/img/image_placeholder_small.jpg";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Refresh from "@material-ui/icons/Refresh";
import Save from "@material-ui/icons/Save";
import PropTypes from "prop-types";
import { isSafePath, saveJSON } from "modules/utils.jsx";
import { NotificationManager } from "react-notifications";

import Api from "modules/api.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const dropdownList = [
  "trigger",
  "bad",
  "bad_exit",
  "redemption",
  "ignore_dets",
  "scanned",
  "customer_zone",
  "bakery_cells",
  "bakery_shelf"
];

class Zones extends React.Component {
  constructor(props) {
    super(props);
    const route = `${props.instanceName}/${props.appName}`;
    this.state = {
      appName: props.app_name,
      frameURL: `/api/frame/${route}/`,
      zonesURL: `/api/new_config/${route}/zones/`,
      filename: props.props.fileName,
      perspectiveInfo: props.perspectiveInfo || {},
      availableZonesFiles: props.availableZonesFiles || [],
      new_zones_path: "",
      annotations: [],
      raw_json: "{}",
      annotation: {},
      image: defaultImage,
      createZonesFileFunction: props.createZonesFileFunction,
      updatePerspectiveIDFunction: props.updatePerspectiveIDFunction,
      cache: false
    };
    this.file_type = "zones";
    this.jsonEndpoint = `/api/json/${this.file_type}/${route}/`;
    this.changeZonesRawJson = this.changeZonesRawJson.bind(this);
    this.changeNewZonesPath = this.changeNewZonesPath.bind(this);
    this.zonesMessage = "";
  }

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadData() {
    this.loadFrame();
    this.loadZones();
  }

  loadFrame() {
    if (this.state.cache) {
      NotificationManager.info("Frame cache is active.", "Will not reload frame.");
      return;
    }
    const cfg = {
      headers: { Authorization: Api.getToken() },
      mode: "cors",
      cache: this.state.cache ? "default" : "reload",
    };
    fetch(this.state.frameURL, cfg).then((res) => {
      if (res.ok) {
        res.blob().then((res) => {this.setState({image: URL.createObjectURL(res), cache: true});});
      }
      else{
        NotificationManager.warning("No frame available for this instance.");
      }
    });
  }

  loadZones() {
    if (!this.zonesFileTargetSpecified()) {
      NotificationManager.warning("No zones configured for this instance.");
      return;
    }
    let params = {};
    if(this.state.filename) {
      params.filename = this.state.filename;
    }
    Api.get(this.state.zonesURL, params).then((res) => {
      this.setState({
        annotations: res.data,
      });
    });
    Api.get(this.jsonEndpoint, params).then((res) => {
      try {
        let raw_json = res.raw_json;
        raw_json = raw_json.replace(/'/g, "\"");
        this.setState({
          raw_json: raw_json,
        });
      } catch(e) {
        NotificationManager.error(e.message);
      }
    });
  }

  validNewZonesPath(path) {
    if (!path) {path = this.state.new_filename;}
    if (path){
      if (this.state.availableZonesFiles.includes(path)){
        this.zonesMessage = "File already exists, choose another name.";
        return false;
      }
      if (!isSafePath(path, this.file_type)){
        this.zonesMessage = "File name should contain '" + this.file_type + "' and end with '.json'.";
        return false;
      }
    }
    this.zonesMessage = "";
    return true;
  }

  saveZones() {
    let loadAfterSave = true;
    if (!this.state.filename && this.validNewZonesPath()) {
      this.setState({zones_path: this.state.new_filename, new_zones_path: ""});
      loadAfterSave = false;
    }
    Api.post(this.state.zonesURL, this.state.annotations).then(() => {
      NotificationManager.success("Saved zones file at " + this.state.filename);
      loadAfterSave && this.loadData();
    }).catch((e) => {NotificationManager.error(e.message);});
  }

  saveZonesJson() {
    let loadAfterSave = true;
    if (!this.state.filename && this.validNewZonesPath()) {
      NotificationManager.warning("It is not yet in use, please add it to your app definition..", "Will create new zones file at " + this.state.new_filename);
      loadAfterSave = false;
      this.setState({zones_path: this.state.new_filename, new_zones_path: ""}, () => {this.state.createZonesFileFunction(this.state.filename);});
    }
    saveJSON(this.state.raw_json,
      this.state.filename || this.state.new_filename,
      this.file_type,
      this.jsonEndpoint) && loadAfterSave && this.loadZones();
  }

  onChange(annotation) {
    this.setState({ annotation });
  }

  onSubmit(annotation) {
    const { geometry, data } = annotation;

    this.setState({
      annotation: {},
      annotations: this.state.annotations.concat({
        geometry,
        data: {
          ...data,
          id: Math.floor(Math.random() * 1000),
        },
      }),
    });
  }

  renderEditor({ annotation, onChange, onSubmit }) {
    return (<Editor annotation={annotation} onChange={onChange} onSubmit={onSubmit} />);
  }

  delete_annotation(annotation) {
    this.setState({
      annotations: this.state.annotations.filter((a) => a !== annotation),
    });
  }

  handleCacheToggle() {
    this.setState({
      cache: !this.state.cache,
    });
  }

  renderContent({ key, annotation }) {
    return (<Content key={key} annotation={annotation} onClose={this.delete_annotation.bind(this)} />);
  }

  renderAnnotation() {
    if (this.state.filename) {
      return (
        <>
          <Button
            color={"success"}
            onClick={() => this.saveZones()}
            style={{float: "right"}}
          >
            <Save /> Save
          </Button>
          <Annotation
            src={this.state.image}
            annotations={this.state.annotations}
            value={this.state.annotation}
            onChange={this.onChange.bind(this)}
            onSubmit={this.onSubmit.bind(this)}
            renderEditor={this.renderEditor.bind(this)}
            renderContent={this.renderContent.bind(this)}
          />
        </>
      );
    } else {
      return (
        <Annotation
          src={this.state.image}
          annotations={this.state.annotations}
          value={this.state.annotation}
          onChange={() => {return null;}}
          onSubmit={() => {return null;}}
          renderEditor={this.renderEditor.bind(this)}
          renderContent={this.renderContent.bind(this)}
        />
      );
    }
  }

  changeZonesRawJson(event) {
    this.setState({raw_json: event.target.value});
    let color = "red";
    try {
      JSON.parse(event.target.value);
      color = "green";
    } catch (e) {
      color = "red";
    }
    event.target.style.borderColor = color;
  }

  changeNewZonesPath(event) {
    this.setState({new_zones_path: event.target.value});
    // event.target.style.borderColor = (this.validNewZonesPath(event.target.value) ? "success" : "error");
  }

  rawJSONComponent() {
    if (!this.state.filename) {return null;}
    return (
      <div style={{display: "flex", marginTop: "1em"}}>
        <div style={{flex: "99%"}}>
          <textarea style={{
            fontSize: "1em",
            fontFamily: "Fira Mono",
            width: "100%",
            height: "5em",
            border: "3px solid green",
            display: "flex"
          }}
          value={this.state.raw_json}
          onChange={this.changeZonesRawJson}></textarea>
        </div>
        <div style={{flex: "1%"}}>
          <Button color={this.zonesFileTargetSpecified() ? "success" : "grey"}
            disabled={!this.zonesFileTargetSpecified()}
            onClick={() => this.saveZonesJson()}
            style={{float: "right"}}>
            <Save /> Save raw JSON
          </Button>
        </div>
      </div>
    );
  }

  newZonesPathComponent() {
    if (this.state.filename) {
      return null;
    }
    const border = this.validNewZonesPath() ? "green" : "red";
    return (
      <div style={{fontFamily: "Fira Mono"}}>
        <span>Create new Zones file at: </span>
        <CustomInput
          style={{minWidth: "50%", height: "fit-content"}}
          onChange={this.changeNewZonesPath}
          value={this.state.new_filename}
          id="newZonesfileName"
          labelText={"New Zones file"}
          inputProps={{type: "string"}}
        />
        { (this.state.filename && !this.validNewZonesPath()) ? <p style={{color: border}}> {this.zonesMessage}</p> : null}
      </div>
    );
  }

  zonesFileTargetSpecified() {
    return this.state.filename || this.validNewZonesPath();
  }

  render() {
    return (
      <div
        style={{paddingTop: "2%", width: "100%", paddingBottom: "3%"}}>
        <PerspectiveChangeButton sourceID={this.state.perspectiveInfo.sourceID} perspectiveID={this.state.perspectiveInfo.perspectiveID} updatePerspectiveIDFunction={this.state.updatePerspectiveIDFunction} />
        <GridContainer style={{width: "100%"}}>
          <GridItem xs={12}>
            <Button color="info" onClick={() => this.loadData()}
              style={{marginRight: "3%", float: "left"}}>
              <Refresh/>
            </Button>
            <FormControlLabel control={<Checkbox style={{float: "left"}}
              onClick={() => this.handleCacheToggle()}
              checkedIcon={
                <Check style={{
                  color: "success",
                  marginLeft: "1%",
                  marginRight: "3%"
                }} className={"checkedIcon"}/>
              }
              icon={
                <Clear className={"uncheckedIcon"} style={{
                  color: "error",
                  marginLeft: "1%",
                  marginRight: "3%"
                }}/>
              }
              checked={this.state.cache}
            />} label="Cache" style={{float: "left"}} />
            {this.renderAnnotation()}
            {this.rawJSONComponent()}
          </GridItem>
        </GridContainer>
        {this.newZonesPathComponent()}

      </div>
    );
  }
}

function Editor(props) {
  const { geometry } = props.annotation;
  if (!geometry) return null;
  let callback = (value) => {
    props.annotation.data = {
      ...props.annotation.data,
      text: value,
    };
    props.onChange(props.annotation);
    props.onSubmit();
  };
  return (
    <div
      className="annotation-popover"
      style={{
        position: "absolute",
        left: `${geometry.x + geometry.width / 2}%`,
        top: `${geometry.y + geometry.height / 2}%`,
        ...props.style,
      }}
    >
      <MenuList role="menu" className="menuList">
        {dropdownList.map((prop, key) => {
          return (
            <MenuItem
              key={key}
              className="dropdownItem"
              onClick={() => callback(prop)}
            >
              {prop}
            </MenuItem>
          );
        })}
      </MenuList>
    </div>
  );
}

Editor.defaultProps = {
  className: "",
  style: {},
};

function Content(props) {
  const { geometry } = props.annotation;
  if (!geometry) return null;

  return (
    <div
      style={{
        position: "absolute",
        left: `${geometry.x}%`,
        top: `${geometry.y + geometry.height - 10}%`,
        background: "white",
        borderRadius: "2px",
        padding: "8px 16px 8px 0",
        ...props.style,
      }}
      className={props.className}
      geometry={geometry}
    >
      <Button
        onClick={() => props.onClose(props.annotation)}
        style={{
          margin: "0 15px",
          color: "black",
          backgroundColor: "white",
          boxShadow: false,
        }}
      >
                x
      </Button>
      {props.annotation.data && props.annotation.data.text}
    </div>
  );
}

function ZonesPage(props = {}) {
  const [appIds, setAppIds] = React.useState([]);
  React.useEffect(() => {
    if (props.instanceName && props.appName) {
      setAppIds([props.appName]);
    } else {
      Api.get("/api/apps/") // deprecate
        .then((ids) => setAppIds(ids))
        .catch((err) => {
          NotificationManager.error(err.message);
          setAppIds([]);
        });
    }
  }, [props]);
  return (
    <>
      {appIds.map((appName, key) => (
        <Zones key={key} appName={appName} instanceName={props.instanceName} zones_path={props.filename} availableZonesFiles={props.availableZonesFiles} createZonesFileFunction={props.createZonesFileFunction} updatePerspectiveIDFunction={props.updatePerspectiveIDFunction} perspectiveInfo={props.perspectiveInfo} props={props} />
      ))}
    </>
  );
}

const propTypes = {
  appName: PropTypes.string,
  availableZonesFiles: PropTypes.array,
  createZonesFileFunction: PropTypes.func,
  instanceName: PropTypes.string.isRequired,
  perspectiveInfo: PropTypes.object,
  updatePerspectiveIDFunction: PropTypes.func,
  zones_path: PropTypes.string,
  fileName: PropTypes.string,
};
Zones.propTypes = propTypes;
ZonesPage.propTypes = propTypes;

export default ZonesPage;
