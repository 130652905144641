import React from "react";

import ChartistGraph from "react-chartist";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import { SessionContext } from "layouts/Dashboard.jsx";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import { successBoxShadow } from "assets/jss/material-dashboard-pro-react.jsx";
import Api from "modules/api.js";

import { weekChart } from "variables/charts";
import { ctTooltips } from "modules/ChartistPlugins.js";

import withI18n from "modules/withI18n";

class IncidentsPerDay extends React.PureComponent {
  constructor(props) {
    super(props);
    const { t } = props;
    this._isMounted = false;
    let chart = JSON.parse(JSON.stringify(weekChart));
    chart.options.plugins = [ctTooltips({ anchorToPoint: true })];
    chart.description = t("charts.incidents_per_day");
    this.state = {
      chart: chart,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.context.device !== prevProps.context.device ||
      this.props.context.timespan !== prevProps.context.timespan ||
      this.props.t !== prevProps.t
    ) {
      const newChart = this.state.chart;
      newChart.data.series[0] = newChart.data.series[0].map(() => 0);
      this.setState({
        chart: newChart,
      });
      this._isMounted &&
        this.loadData(this.props.context.device, this.props.context.timespan);
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this._isMounted &&
      this.loadData(this.props.context.device, this.props.context.timespan);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadData(device, timespan) {
    Api.get("/api/statistics/incidents", {
      device_id: device,
      timespan: timespan,
    }).then((res) => {
      if (this._isMounted) {
        res.data.options.plugin = this.state.chart.plugin;
        if (
          this.props.context.device === device &&
          this.props.context.timespan === timespan
        ) {
          this.setState({
            chart: res.data,
          });
        }
      }
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <GridItem xs={12} sm={12} md={6}>
        <Card chart className={classes.cardHover}>
          <CardHeader
            style={{
              background: "#FFFFFF",
              color: "#222222",
              paddingTop: "3%",
              paddingBottom: "3%",
              paddingLeft: "3%",
              paddingRight: "3%",
              marginTop: "3%",
              marginBottom: "2%",
              marginLeft: "3%",
              marginRight: "3%",
              boxShadow: successBoxShadow.boxShadow,
            }}
          >
            <ChartistGraph
              className="ct-chart-info-colors"
              data={this.state.chart.data}
              type="Line"
              options={this.state.chart.options}
              listener={weekChart.animation}
            />
          </CardHeader>
          <CardBody>
            <p
              style={{
                fontFamily:
                  "\"BrandFont\", \"Roboto\", \"Helvetica\", \"Arial\",  sans-serif",
                color: "#222222",
                fontWeight: 300,
                fontSize: "16px",
              }}
              className={classes.cardTitle}
            >
              {this.state.chart.description}
            </p>
          </CardBody>
        </Card>
      </GridItem>
    );
  }
}

function withSessionContext(Component) {
  return function WrapperComponent(props) {
    return (
      <SessionContext.Consumer>
        {(state) => <Component {...props} context={state} />}
      </SessionContext.Consumer>
    );
  };
}

export default withI18n(
  withSessionContext(withStyles(dashboardStyle)(IncidentsPerDay))
);
