import PropTypes from "prop-types";
import React from "react";
import { Redirect, withRouter } from "react-router-dom";

// @material-ui/icons
import Lock from "@material-ui/icons/Lock";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Api from "modules/api.js";


class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      hostname: undefined,
      redirectToDashboard: false,
      error: "",
      cardAnimaton: "cardHidden",
      loginEmail: "",
      loginEmailState: "error",
      loginPassword: "",
      loginPasswordState: "error",
      loginButtonEnabled: false,
    };
    Api.get("/api/auth/context").then((res) => {
      this.setState({hostname: res.hostname});
    }).catch(() => {return null;});
    this.loginClick = this.loginClick.bind(this);
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    let emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return emailRex.test(value);
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    return value.length >= length;
  }

  change(event, stateName, type) {
    switch (type) {
    case "email":
      this.setState({ [stateName]: event.target.value });
      if (this.verifyEmail(event.target.value)) {
        this.setState({ [stateName + "State"]: "success" });
      } else {
        this.setState({ [stateName + "State"]: "error" });
      }
      break;
    case "password":
      this.setState({ [stateName]: event.target.value });
      if (this.verifyLength(event.target.value, 1)) {
        this.setState({ [stateName + "State"]: "success" });
      } else {
        this.setState({ [stateName + "State"]: "error" });
      }
      break;
    default:
      break;
    }
    this.setState({
      loginButtonEnabled:
        this.state.loginEmailState === "success" ||
        this.state.loginPasswordState === "success",
    });
  }
  loginClick(event) {
    event.preventDefault();

    if (this.state.loginEmailState === "") {
      this.setState({ loginEmailState: "error" });
    }
    if (this.state.loginPasswordState === "") {
      this.setState({ loginPasswordState: "error" });
    }
    let canLogin =
      this.state.loginEmailState === "success" &&
      this.state.loginPasswordState === "success";
    this.setState({
      loginButtonEnabled: canLogin,
    });

    if (canLogin) {
      return Api.login(this.state.loginEmail, this.state.loginPassword)
        .then(() => {
          this.setState({
            error: "",
            redirectToDashboard: true,
          });
        })
        .catch((error) => {
          let msg = "Login was not successful.";
          if (error.message) {
            msg = error.message;
          }
          this.setState({error: msg, loginPasswordState: "error", loginEmailState: "error", loginButtonEnabled: false});
        });
    }
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({
          cardAnimaton: "",
        });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  showError() {
    // if (this.state.error) {
    return <p className="error-message">{this.state.error}</p>;
    // }
  }

  render() {
    const { classes } = this.props;
    if (this.state.redirectToDashboard) {
      return <Redirect to="/" push />;
    }

    return (
      <div className={classes.container} >
        <GridContainer justifyContent="center" >
          <GridItem xs={10} sm={10} md={5} style={{ width: "90%" }}>
            <form onSubmit={this.loginClick}>
              <Card login className={classes[this.state.cardAnimaton]}
                style={{width: "100%", marginTop: "-15%", paddingTop: "2%", paddingBottom: "2%", paddingLeft: "7%", paddingRight: "7%", borderRadius: "27px", color: "#222222"}} >
                <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`}>
                  <h4
                    style={{
                      transition: "all 500ms linear",
                      display: "block",
                      opacity: "1",
                      transform: "translate3d(0px, 0, 0)",
                      // textTransform: "uppercase",
                      paddingTop: "5%",
                      // paddingBottom: "15px",
                      marginBottom: "-2%",
                      fontSize: "40px",
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                      lineHeight: "50px",
                      overflow: "auto",
                      // fontStyle: "normal",
                      fontFamily: "\"BrandFont\", \"Montserrat\", \"Roboto\", \"Helvetica\", \"Arial\", sans-serif"
                    }}
                  >
                    Signatrix
                  </h4>
                </CardHeader>
                <CardBody style={{width: "100%"}}>
                  {this.showError()}
                  <span style={{
                    fontFamily: "Fira Mono",
                    letterSpacing: "0.02857em",
                    color: "#333333",}}
                  >
                    {this.state.hostname}</span>

                  <CustomInput
                    success={this.state.loginEmailState === "success"}
                    error={this.state.loginEmailState === "error"}
                    labelText="Email Address"
                    id="loginemail"
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      onChange: (event) => this.change(event, "loginEmail", "email"),
                      type: "email",
                      autoComplete: "username"
                    }}
                  />
                  <CustomInput
                    success={this.state.loginPasswordState === "success"}
                    error={this.state.loginPasswordState === "error"}
                    labelText="Password"
                    id="loginpassword"
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      onChange: (event) => this.change(event, "loginPassword", "password"),
                      type: "password",
                      autoComplete: "current-password"
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter} style={{marginTop: "1%", marginBottom: "4%"}}>
                  <Button
                    disabled={!this.state.loginButtonEnabled}
                    color="white"
                    type="submit"
                    style={{
                      fontFamily: "Fira Mono",
                      letterSpacing: "0.02857em",
                      width: "45%",
                      borderRadius: "7px",
                      color: !this.state.loginButtonEnabled ? "#999999" : "#444444",
                      boxShadow:"5px 5px 6px 0 rgba(70, 70, 70, 0.05), -5px -5px 6px 0 rgba(230, 230, 230, 0.09)"
                      // boxShadow: !this.state.loginButtonEnabled ? "5px 5px 6px 0 rgba(70, 70, 70, 0.05), -5px -5px 6px 0 rgba(230, 230, 230, 0.09)" : "5px 5px 6px 0 rgba(60, 60, 60, 0.1), -5px -5px 6px 0 rgba(230, 230, 230, 0.4)"
                    }}
                  >
                    {this.state.loginButtonEnabled ? "Login" : <Lock />}
                  </Button>
                </CardFooter>
                <br />
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(loginPageStyle)(LoginPage));
