import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";

// @material-ui/core components
import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import Toolbar from "@material-ui/core/Toolbar";
import withStyles from "@material-ui/core/styles/withStyles";

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";

import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "./HeaderLinks";

import headerStyle from "assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";
import DefinitionsFileSelect from "components/DefinitionsFileSelect.jsx";
import DeviceSelector from "components/DeviceSelect.jsx";
import IncidentCount from "components/IncidentCount.jsx";
import InstanceSelect from "components/InstanceSelect.jsx";
import ObjectClassSelect from "components/ObjectClassSelect";
import TimespanSelect from "components/TimespanSelect";

function Header({ ...props }) {
  function makeBrand() {
    let name;
    props.routes.map((prop) => {
      if (prop.collapse) {
        prop.views.map((prop) => {
          if (prop.path === props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      }
      if (prop.path === props.location.pathname) {
        name = prop.name;
      }
      return null;
    });
    if (name) {
      return name;
    } else {
      return "Signatrix";
    }
  }
  const { classes, color } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });
  const sidebarMinimize = classes.sidebarMinimize;
  const view = makeBrand();
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container} style={{ paddingLeft: "0%", paddingRight: "3%" }}>
        <Hidden smDown implementation="css">
          <div className={sidebarMinimize} style={{ marginTop: "30%" }}>
            {props.miniActive ? (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <MoreVert className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Hidden>

        <div
          className={classes.flex}
          style={{ width: "20%", marginLeft: "3%" }}
        >
          {["local.statistics", "cloud.statistics"].includes(view) ? <ObjectClassSelect /> : null}
        </div>
        <div
          className={classes.flex}
          style={{ width: "auto", marginRight: "2%" }}
        >
          {["local.statistics", "cloud.statistics"].includes(view) ? <IncidentCount /> : null}
        </div>
        <div
          className={classes.flex}
          style={{ minWidth: "15%", marginRight: "2%" }}
        >
          {["local.statistics", "cloud.statistics", "local.checkout"].includes(view) ? (
            <TimespanSelect props={props}/>
          ) : null}
        </div>

        {["cloud.statistics", "cloud.incidents"].includes(view) ? (
          <div
            className={classes.flex}
            style={{ width: "15%", marginRight: "2%" }}
          >
            <DeviceSelector />
          </div>
        ) : null}

        {["local.definitions"].includes(view) ? (
          <div
            // className={classes.flex}
            style={{ width: "15%", marginRight: "2%" }}
          >
            <DefinitionsFileSelect />
          </div>) : null}
        {["local.definitions", "local.controlCenter", "local.incidents", "cloud.incidents", "local.config"].includes(view) ? (
          <div
            // className={classes.flex}
            style={{ width: "15%", marginRight: "2%"}}
          >
            <InstanceSelect />
          </div>
        ) : null}
        <Hidden smDown implementation="css">
          <HeaderLinks />
        </Hidden>

        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  routes: PropTypes.arrayOf(PropTypes.object.isRequired),
  handleDrawerToggle: PropTypes.func,
  location: PropTypes.object,
  miniActive: PropTypes.bool,
  sidebarMinimize: PropTypes.func,

};

export default withStyles(headerStyle)(Header);
